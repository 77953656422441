import React, {useState,useEffect} from "react"
import Layout from "./layout"

import { graphql } from "gatsby"
import InnerHTML from 'dangerously-set-html-content'

export default function Book({data,location}) {
  const post = data.markdownRemark
  const [muted,setMuted] = useState(false);
  console.log(post)
  const vidStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  }
  useEffect(e => {
    if(typeof window !== "undefined"){
      if(window.innerWidth<768){
        setMuted(true);
      }
    }
  },[])
  const vidElement = (src,i) => {
    return <video autoPlay playsInline muted loop style={vidStyle} key={i} src={src}></video>
  }

  const vid = post.frontmatter.background_videos ? <video autoPlay playsInline muted loop style={vidStyle} key={1000} src={post.frontmatter.background_videos[0].publicURL}>a</video> : ""
  var iframes
  if(!muted){
    iframes = post.frontmatter.video_ids.map((id,i) => (
      <iframe  allow={`${muted ? "mute; ":""} autoplay; encrypted-media`} title={post.fields.slug+(i+1)} key={(i+1)} id="ytplayer" type="text/html" height="170px"
      src={`https://www.youtube.com/embed/${id}?autoplay=1&modestbranding=0&autohide=1&showinfo=0&controls=0&muted=1`}
      frameBorder="0"></iframe>
    ));
    iframes[4] = vid;
  } else {
    iframes = post.frontmatter.background_videos.map(v => v.publicURL).map(vidElement)
    let temp = iframes[4];
    iframes[4] = iframes[0];
    iframes[0] = temp;
  }

  console.log("videowall ",post.frontmatter)
  return (
    <Layout data={data}>
    <div>
    <iframe title={post.fields.slug} src="https://olafwempe.com/mp3/silence/silence.mp3" type="audio/mp3" allow="autoplay" id="audio" style={{display: "none"}}></iframe>
      <InnerHTML html={post.html} />
      <div className="ids">{iframes}<div className="spacer"></div></div>
    </div>
    </Layout>
  )
}
