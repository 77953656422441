import VideoWall from "../components/videowall"
import { graphql } from "gatsby"

export default VideoWall

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      html
      frontmatter {
        title
        tags
        video_ids
        background_images {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        background_videos {
          publicURL
        }
      }
      fields {
        slug
        table
      }
    }
  }
`
